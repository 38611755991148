@import "../../../../assets/scss/_variables.scss";
@import "../../../../assets/scss/_mixins.scss";

.tab-list {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $brandDeepblue;

  @include phone-large {
    margin-top: 35px;
  }

  li {
    border-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
   
    @include tablet-large {
      width: 32%;
      padding: 0 2px;
    } 

  }
}

.tab-content{
  min-height: 500px;
  padding-top: 3%;

  @include phone-large {
    min-height: 100%;
  }
}

.tabs-container {
  padding-top: 5%;
  position: relative;

  @include phone-large {
    position: relative;
    max-width: 95%;
    margin: auto;
  }

  .update-info {
    position: absolute;
    top: 0px;
    right: 0px;

    @include phone-large {
      top: 12px !important;
      right: 12px !important;
    }
  }
}
