@import "../../../../../assets/scss/_variables.scss";
@import "../../../../../assets/scss/_mixins.scss";

.tab-container {
  padding-top: 0px;
  margin-right: 0px;

  @include tablet-small {
    width: 30%;
    margin-right: 0px;
  }

}

.tab-list-item {
  display: block;
  padding: 20px;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  width: 255px;
  text-align: center;
  background-size: 30px;
  background-color: $brandLightGrey;

  @include desktop-large {
    width: 200px;
  }

  @include desktop-small {
    width: 180px;
    font-size: 1rem;
  }

  @include tablet-large {
    width: 100%;
  }

  span {
    @include tablet-large {
      display: none;
    }
  }

  &:hover {
    text-decoration: none;
    background-color: $brandDeepblue;
    color: $brandWhite;
    cursor: pointer;
  }

  &.medicos {
    @include tablet-large {
      background: url(../../../../../assets/images/icons/medical-report-b.svg) no-repeat center $brandLightGrey;
      background-size: 30px;
    }

    &.tab-list-active {
      @include tablet-large {
        background: url(../../../../../assets/images/icons/medical-report-w.svg) no-repeat center $brandDeepblue;
        background-size: 30px;
      }
    }
  }

  &.medicacion , &.recetas {
    @include tablet-large {
      background: url(../../../../../assets/images/icons/medication-b.svg) no-repeat center $brandLightGrey;
      background-size: 25px;
    }

    &.tab-list-active {
      @include tablet-large {
        background: url(../../../../../assets/images/icons/medication-w.svg) no-repeat center $brandDeepblue;
        background-size: 25px;  
      }
    }
  }

  &.justificantes {
    @include tablet-large {
      background: url(../../../../../assets/images/icons/note-b.svg) no-repeat center $brandLightGrey;
      background-size: 28px;
    }

    &.tab-list-active {
      @include tablet-large {
        background: url(../../../../../assets/images/icons/note-w.svg) no-repeat center $brandDeepblue;
        background-size: 28px; 
      }    
    }
  }
  &.protocolos {
    @include tablet-large {
      background: url(../../../../../assets/images/icons/protocolos-b.svg) no-repeat center $brandLightGrey;
      background-size: 20px;
    }

    &.tab-list-active {
      @include tablet-large {
        background: url(../../../../../assets/images/icons/protocolos-w.svg) no-repeat center $brandDeepblue;
        background-size: 20px; 
      }    
    }
  }
}

.tab-list-active {
  background-color: $brandDeepblue;
  color: $brandWhite;
}


